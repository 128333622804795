// eslint-disable-next-line no-unused-vars
const isIphoneX = () => {
    // iPhone X、iPhone XS
    var isIPhoneX = /iphone/gi.test(window.navigator.userAgent) && screen.height == 812 && screen.width == 375
        // console.log(isIPhoneX, screen, 'x');
        // iPhone XS Max
    var isIPhoneXSMax = /iphone/gi.test(window.navigator.userAgent) && window.devicePixelRatio && window.devicePixelRatio === 3 && window.screen.width === 414 && window.screen.height === 896;
    // console.log(isIPhoneX, 'xsm');
    // iPhone XR
    var isIPhoneXR = /iphone/gi.test(window.navigator.userAgent) && window.devicePixelRatio && window.devicePixelRatio === 2 && window.screen.width === 414 && window.screen.height === 896;
    // console.log(isIPhoneX, 'xsr');
    return (isIPhoneX || isIPhoneXSMax || isIPhoneXR);
}

// eslint-disable-next-line no-unused-vars
const fixButtonBottom = () => {
    console.log(isIphoneX(), 'isIphonex')
    return isIphoneX() ? `90px` : '50px';

}

export { fixButtonBottom, isIphoneX }