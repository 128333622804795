<template>
  <div>
    <van-nav-bar
      :title="couponInfo?.sourceTitle"
      left-text="返回"
      left-arrow
      fixed
      placeholder
      @click-left="onClickLeft"
    />
    <div class="shop-header">
      <img :src="couponInfo?.imgUrl" style="width: 100%" />
    </div>
    <div class="shop-info">
      <div class="shop-title">{{ couponInfo?.couponTitle }}</div>
      <div class="shop-subtitle">
        <div class="shop-price">
          面值
          <span class="shop-curry">¥</span>
          <span class="price">{{ couponInfo?.favorablePrice }}</span>
        </div>
        <div class="shop-showtime">上映日期: {{ couponInfo?.saleTime }}</div>
        <div class="shop-label">
          <p class="goodlabel">{{ couponInfo?.couponSubTitle }}</p>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div class="shop-movie">
      电影：
      <a @click="gotoMovie">{{ couponInfo?.sourceTitle }}</a>
    </div>
    <div class="clear"></div>
    <div class="shop-remark">—————— 礼券详情 ——————</div>
    <div class="shop-img">
      <template
        v-for="(picture, index) in couponInfo?.pictures?.dataList"
        :key="index"
      >
        <div>
          <img :src="picture?.pictureUrl" />
        </div>
      </template>
    </div>
    <div class="empty"></div>
    <div class="fixButton" :style="`bottom:${fixButtonBottom}`">
      <van-button type="primary" :block="true" @click="gainCopon">{{
        couponInfo?.couponType === "Coupon" ? `免费领取` : `领代金券`
      }}</van-button>
      <van-number-keyboard safe-area-inset-bottom />
    </div>

    <van-popup
      v-model:show="bindPhoneShow"
      style="width: 100%"
      position="bottom"
      round
    >
      <van-form @submit="onSubmit">
        <van-field
          v-model="mobile"
          name="phone"
          label="手机号"
          placeholder="手机号"
          :rules="[{ required: true, message: '请填写手机号' }]"
        />
        <div style="margin: 16px">
          <van-button round block type="primary" @click="submitPhone"
            >提交</van-button
          >
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { searchCouponInfo, gainCouponProcess } from "@/api/coupon.js";
import { useStore } from "vuex";
import { Toast } from "vant";
import { fixButtonBottom } from "@/utils/index.js";
export default {
  setup() {
    const couponInfo = ref(null);
    const router = useRouter();
    const route = useRoute();
    const userInfo = ref("");
    const store = useStore();
    const bindPhoneShow = ref(false);
    const mobile = ref(null);
    onMounted(() => {
      console.log(fixButtonBottom(), "fixbutton");
      userInfo.value = useStore().getters["user/getUserInfo"];
      if (route.params.id) {
        searchCouponInfo({ CouponId: route.params.id }).then((res) => {
          couponInfo.value = res.data;
          console.log(couponInfo.value, "value");
        });
      }
    });
    function submitPhone() {
      store.dispatch("user/setMobile", mobile.value);
      bindPhoneShow.value = false;
    }
    const onClickLeft = () => {
      router.back();
    };
    const gainCopon = () => {
      if (userInfo.value.mobile === null || userInfo.value.mobile === "") {
        Toast("请先绑定手机号");
        bindPhoneShow.value = true;
      } else {
        gainCouponProcess({
          openId: userInfo.value.openId,
          couponId: route.params.id,
          mobile: userInfo.value.mobile,
          businessCode: userInfo.value.businessCode,
        }).then((res) => {
          Toast(res.msg);
        });
      }
    };
    const gotoMovie = () => {
      router.push({
        path: `/movie/detail/${couponInfo.value.sourceId}`,
      });
    };

    return {
      onClickLeft,
      couponInfo,
      gainCopon,
      gotoMovie,
      fixButtonBottom,
      mobile,
      bindPhoneShow,
      submitPhone,
    };
  },
};
</script> 
<style lang="scss" scoped>
.shop-header {
  width: 100%;
  background: transparent;
  background-color: #fff;
  text-align: center;
}
.shop-info {
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  background-color: #fff;
}
.shop-title {
  font-size: 1.3rem;
}
.shop-subtitle {
  display: flex;
  line-height: 1.5rem;
  margin-top: 0.5rem;
}
.shop-img > div > img {
  width: 100%;
}
.shop-price {
  padding-right: 0.3rem;
  font-size: 0.9rem;
}
.shop-showtime {
  margin-left: 1rem;
  font-size: 0.7rem;
}
.shop-curry {
  padding-left: 0.7rem;
  color: #ff9600;
  font-size: 0.5rem;
  font-weight: normal;
}
.price {
  font-weight: bold;
  font-size: 1.3rem;
  color: #ff9600;
}
.shop-label {
  margin-left: 2rem;
}
.goodlabel {
  background-color: #ff9600;
  color: #ffffff;
  border-radius: 0.3rem;
  font-size: 0.7rem;
  margin-block-start: 0em;
  margin-block-end: 0em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0rem 0.3rem;
}
.shop-movie {
  padding: 0.5rem 0.7rem;
  margin-top: 0.5rem;
  background-color: #fff;
  border-radius: 0.3rem;
}
.empty {
  height: 100px;
}
.fixButton {
  position: fixed;
  width: 100%;
  bottom: 50px;
}
.shop-remark {
  line-height: 2rem;
  text-align: center;
  color: #b2b2b2;
  font-size: 0.7rem;
}

.clear {
  clear: both;
}
</style>