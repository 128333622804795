<template>
  <div>
    <van-nav-bar
      title="领券"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- <van-search
      v-model="value"
      placeholder="请输入搜索关键词"
      input-align="center"
    /> -->
    <van-tabs v-model:active="active" animated sticky swipeable>
      <van-tab
        v-for="(item, index) in tabs"
        :title="item.title"
        :name="item.key"
        :key="index"
      >
        <card-list
          :api="searchCoupons"
          :apiParams="{ couponType: item.key, sourceType: 'MovieTicket' }"
        >
          <template v-slot:default="defaultProps">
            <div class="item" @click="gotoDetail(defaultProps.item)">
              <div class="image" style>
                <img :src="defaultProps.item.imgUrl" />
              </div>
              <div class="item-desc">
                <div class="desc">
                  <p class="movie-title">{{ defaultProps.item.couponTitle }}</p>
                  <p class="movie-subtitle">
                    {{ defaultProps.item.couponSubTitle }}
                  </p>
                </div>
                <div class="on-movie show">
                  {{ defaultProps.item.couponStatus }}
                </div>
              </div>
            </div>
            <!-- {{ defaultProps }} -->
          </template>
        </card-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import CardList from "@/components/CardList.vue";
import { searchCoupons } from "@/api/coupon.js";
import { onMounted, ref } from "vue";
export default {
  components: { CardList },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const tabs = [
      { title: "领优惠券", key: "Coupon" },
      { title: "领代金券", key: "Voucher" },
    ];
    const active = ref("Coupon");
    onMounted(() => {
      if (route.params.type) {
        active.value = route.params.type;
      }
    });
    const onClickLeft = () => {
      router.back();
    };
    const gotoDetail = (item) => {
      router.push({ path: `/coupon/detail/${item.couponId}` });
    };
    return { searchCoupons, tabs, active, onClickLeft, gotoDetail };
  },
};
</script>

<style lang="scss" scoped>
.item {
  border-radius: 0.85em;
  border: 1px solid #efefef;
  box-shadow: 8px 5px 5px 8px #efefef;
  background: #ffffff;
}
.movie-title {
  font-size: 0.9rem;
  font-weight: 600;
  margin-block-start: 0em;
  margin-block-end: 0em;
}
.desc {
  width: 100%;
}
.image {
  width: 100%;
  border-radius: 0.85em;
}
.image > img {
  border-radius: 0.85em 0.85em 0 0;
  width: 100%;
  height: 100%;
}
.movie-subtitle {
  font-size: 0.9rem;
  font-weight: 400;
  margin-block-start: 0em;
  margin-block-end: 0em;
  color: #a0a0a0;
}
.on-movie {
  width: 5rem;
  text-align: right;
  height: 3rem;
  line-height: 3rem;
}
.show {
  color: #ff9600;
  font-weight: bold;
  font-size: 0.8rem;
}
.end {
  color: gray;
  font-weight: bold;
  font-size: 0.8rem;
}
.item-desc {
  display: flex;
  padding: 0.3rem 1rem 0.3rem 1rem;
}
::v-deep .van-cell {
  background-color: transparent;
}
</style>